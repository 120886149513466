.landing {
   .card-panel, .card {
      border-radius: 5px;
      background-color: rgba(255,255,255,0.5);
      height: calc(97vh - 95px);
      h4 {
         font-size: 2.1rem;
         margin-top: 0;
      }
      h6 {
         line-height: 1.2;
      }
   }
}

#product {
   .landing {
      .card-panel,
         .card {
            height: max-content;
         }
   }
}

@media only screen and (max-width: 990px) {
.landing {
      .card-panel {
         height: fit-content;
      }
   }
}
@import "config";

.title {
    margin: 0px;
    padding-bottom: 0px !important;
    font-size: 1.6rem;
}

.content {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.zoom {
    @include animation(zoom, 0s, 0.3s);
}

@include keyframe(zoom) {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

.zoomOut {
    @include animation(zoom-out, 0s, 0.3s);
}

@include keyframe(zoom-out) {
    from {
        transform: scale(1)
    }

    to {
        transform: scale(0)
    }
}

@media only screen and (max-width: 480px) {
    .title {
        font-size: 1.5rem;
    }

    .position {
        max-height: 86vh;
    }
}
@use './index.scss' as *;
@import "config";

.carousel {
    position: relative;
    height: inherit !important;
    overflow: hidden;
    background-color: $secondary-color-dark;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
}

.carouselItem {
    font-family: $secondary-font-stack;
    padding: 1em;
    font-size: 1.6rem;

    h5 {
        font-style: italic;
        line-height: 1.5 !important;
        
    }

    h4 {
        background-color:unset !important;
    }

    img {
        width: 70%;
        padding-top: 5px;
        max-height: 300px;
    }
}

.itemContent {
    height: 90%;
    width: 90%;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.itemWithOnlyText {
    justify-content: center;
}

.carouselNav {
    width: 100%;
    padding: 20px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
}

%buttons {
width: 10px;
height: 10px;
display: inline-block;
border-radius: 50%;
margin: 0 5px;
cursor: pointer;
}

.carouselButton {
    @extend %buttons;
    background: rgba(255, 255, 255, 0.2);
}

.carouselButtonSelected {
    @extend %buttons;
    background: rgba(255, 255, 255, 0.8);
}

%arrows {
position: absolute;
top: 50%;
cursor: pointer;
}

.rightArrow {
    @extend %arrows;
    right: 0;
}

.leftArrow {
    @extend %arrows;
    left: 0;
}

/* NEXT SLIDE TRANSITION */

.slideInNext {
    @include animation(slide-next, 0s, 0.3s);
}

@include keyframe(slide-next) {
    from {
        transform: translateX(var(--width))
    }
    
    to {
        transform: translateX(0)
    }
}

/* PREV SLIDE TRANSITION */

.slideInPrev {
    @include animation(slide-prev, 0s, 0.3s);
}

@include keyframe(slide-prev) {
    from {
        transform: translateX(-100vw)
    }

    to {
        transform: translateX(0)
    }
}


@media only screen and (max-width: 600px) {
    .carouselItem {
        height: calc(97vh - 95px);
    }
}

@media only screen and (max-width: 420px) {
    .itemContent {
        width: 80%;
    }

    .carouselItem h4 {
        font-size: 28px;
    }

    .carouselItem h5 {
        font-size: 18px;
    }

    .rightArrow {
        right: -10px;
    }

    .leftArrow {
        left: -10px;
    }
}

@media only screen and (max-height: 675px) {
    .carouselItem {
        height: calc(97vh - 75px);
    }

    .carouselItem h4 {
        font-size: 28px;
    }

    .carouselItem h5 {
        font-size: 18px;
    }
}
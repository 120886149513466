@media only screen and (min-width: 990px) {
.card-content {
   min-height: 380px;
}
}


@media only screen and (max-width: 600px) {
   form label,
   label {
      font-size: 13px;
   }
}
//set animations:
@mixin keyframe($animation-name) {
   @-webkit-keyframes #{$animation-name} {
      @content;
   }

   @-moz-keyframes #{$animation-name} {
      @content;
   }

   @-o-keyframes #{$animation-name} {
      @content;
   }

   @keyframes #{$animation-name} {
      @content;
   }
}

@mixin animation ($animation, $delay, $duration, $timing-function: ease, $fillmode: forwards) {
   -webkit-animation-name: $animation;
   -webkit-animation-delay: $delay;
   -webkit-animation-duration: $duration;
   -webkit-animation-timing-function: $timing-function;
   -webkit-animation-fill-mode: $fillmode;

   -moz-animation-name: $animation;
   -moz-animation-delay: $delay;
   -moz-animation-duration: $duration;
   -moz-animation-timing-function: $timing-function;
   -moz-animation-fill-mode: $fillmode;

   -o-animation-name: $animation;
   -o-animation-delay: $delay;
   -o-animation-duration: $duration;
   -o-animation-timing-function: $timing-function;
   -o-animation-fill-mode: $fillmode;

   animation-name: $animation;
   animation-delay: $delay;
   animation-duration: $duration;
   animation-timing-function: $timing-function;
   animation-fill-mode: $fillmode;
}
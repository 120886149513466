@import 'materialize-css/sass/components/_color-variables.scss';

// color theme:
$primary-color: color("pink", "accent-2");
$primary-color-light: lighten($primary-color, 15%);
$primary-color-dark: darken($primary-color, 15%);
$secondary-color: color("blue-grey", "lighten-2");
$secondary-color-light: lighten($secondary-color, 30%);
$secondary-color-dark: rgba(38, 50, 56, 0.85);
$success-color: color("green", "base");
$error-color: color("red", "base");
$link-color: $primary-color;
$link-color-hover: lighten($link-color, 5%);
$placeholder-text-color: $secondary-color;
$input-focus-color: $primary-color-dark;

$background-color: color("grey", "lighten-3");

// general:
* {
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}

body,
html {
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   background-color: $background-color;
   scroll-behavior: smooth;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   position: relative;
}

main {
   flex: 1 0 auto;
}

section {
     min-height: calc(100vh - 80px);
  }

.row {
   margin-bottom: unset !important;
}

// fonts:
$secondary-font-stack: Lato,
'Helvetica Neue',
Arial,
Helvetica,
sans-serif;

// navbar:
$sidenav-bg-color: $secondary-color-light;
  header {
     z-index: 999 !important;
  }

// buttons:
$button-raised-background: $primary-color;
.btn, .btn-flat {
   border-radius: 20px !important;
}

// links:
a {
   &:hover {
      color: $link-color-hover;
   }
}

li {
   a {
      &:hover {
         color: unset;
      }
   }
      a.btn-flat {
         text-transform: none;
      }
}

// tooltip:
.material-tooltip {
   padding: 8px !important;
   min-height: 14px !important;
   background-color: rgba(0, 0, 0, 0.45) !important;
   border-radius: 4px !important;
}

// form:
$input-font-size: 1rem;
label {
   font-size: 1rem;
   color: $secondary-color-light;
}
form label {
   font-size: 0.9rem;
   color: $secondary-color-dark;
}

// progress bar:
progress {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   
   max-width: 300px;
   height: 12px;
   border: none;
   border-radius: 5px;
   background-color: $secondary-color-dark;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
   color: $secondary-color-light;
   &::-webkit-progress-value {
      background-color: $secondary-color-light;
      border-radius: 5px;
   }
   &::-webkit-progress-bar {
      background-color: $secondary-color-dark;
      border-radius: 5px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
   }
   &::-moz-progress-bar {
      background-color: $secondary-color-light;
      border-radius: 5px;
   }
}

// modal:
.modal {
   display: block !important;
   top: calc(50vh - 47% + 64px);
   max-height: 85% !important;

   .modal-footer {
      .btn {
         margin: 0.5rem !important;
      }
   }
}
.modal-overlay {
   display: block;
   background-color: rgba(0, 0, 0, .5) !important;
   z-index: 1222 !important;
   min-height: 100vh !important;
   width: 100vw !important;
   top: 0 !important;
}
.card-panel {
   padding: 20px !important;
}

//footer:
  footer {
     bottom: 0;
     position: absolute;
     width: 100%;
     background-color: none;

     .container {
      border-top: 1.5px solid $primary-color-dark;
      justify-content: center;
     }
  }

       .footer-padding {
          padding-bottom: 75px;
       }

       .row.footer-padding {
          margin-bottom: 0;
       }

@import 'materialize-css/sass/materialize.scss';
@import 'utils';
@import 'mobile';
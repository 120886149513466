.delete-btn {
    padding-right: 0px;
}

.votes-icon {
    margin-right: 3% !important;
    &:last-of-type {
        margin-right: 0px !important;
    }
}

.right {
    i {
        padding-left: 5px;
    }
}

/*
.strong-positive {
    color: #15fd4f !important;
}

.positive {
    color: #b5fc43 !important;
}

.neutral {
    color: #ffea00 !important;
}

.negative {
    color: #ffae35 !important;
}

.strong-negative {
    color: #ff4c16 !important;
}

.strong-positive, .positive, .neutral, .negative, .strong-negative {
    font-weight: bold;
}
*/